<template>
    <div class="content-main">
        <div class="filter-wrap">
            <expand-filter
                marginBottom="0px"
                borderRadius="0px"
                :closeWidth="1130"
                :formData="formData"
                @clickBtn="clickBtn"
                @changeBtnFormType="changeBtnFormType"
                @changeSel="changeSel"
                @changeCascader="changeCascader"
            >
                <el-input
                    slot="slotCom"
                    readonly
                    v-model="departmentName"
                    style="width: 160px;margin: 0 10px 10px 0"
                    placeholder="部门权限"
                    @focus="handlerDepartmentalCheck"
                ></el-input>
            </expand-filter>
            <div class="button-line"></div>
            <div class="options">
                <el-button
                    v-has-permi="['videomonitoring:synchronization']"
                    type="success"
                    class="sync"
                    size="medium"
                    @click="handlerClickSync"
                >一键同步</el-button>
                <el-dropdown
                    v-has-permi="['videomonitoring:edit']"
                    type="warning"
                    style="margin-left: 10px"
                    @command="handlerBatchOperation"
                >
                    <el-button type="primary">
                        批量操作<i
                            class="el-icon-arrow-down el-icon--right"
                            style="margin-left:8px;"
                        ></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1"
                            >设置分组</el-dropdown-item
                        >
                        <el-dropdown-item command="2"
                            >设置权限</el-dropdown-item
                        >
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <table-data
            id="table"
            ref="table"
            v-loading="loadingTable"
            :config="table_config"
            :tableData="table_data"
            :height="table_config.height"
            @switchHandler="switchHandler"
        >
            <template v-slot:operation="slotData">
                <el-button
                    v-has-permi="['videomonitoring:edit']"
                    type="text"
                    @click="edit(slotData.data)"
                >编辑
                </el-button>
            </template>
        </table-data>
        <pagination
            :total="total"
            :page.sync="listQuery.pageNum"
            :limit.sync="listQuery.pageRow"
            @pagination="getList"
        />
        <!-- 批量操作 -->
        <dialog-wrapper
            :dialog-obj="dialogObj"
            @handleClose="handlerCloseDialog"
        >
            <set-up-groups
                v-if="dialogObj.dialogVisible"
                :dialogObj="dialogObj"
                :deviceList="deviceList"
                :grouping="grouping"
                :selPopUpDepartmentObjData="selPopUpDepartmentObjData"
                @handlerDepartmentalAuthorityDialog="
                    handlerDepartmentalAuthorityDialog
                "
                @handlerCloseDialog="handlerCloseDialog"
                @handlerDetermine="handlerDetermine"
            ></set-up-groups>
        </dialog-wrapper>
        <!-- 编辑弹窗 -->
        <dialog-wrapper
            :dialog-obj="dialogEditObj"
            @handleClose="handlerEditCloseDialog"
        >
            <editing-equipment
                v-if="dialogEditObj.dialogVisible"
                :editRuleForm="editRuleForm"
                :grouping="grouping"
                :classList="classList"
                :selPopUpDepartmentObjData="selPopUpDepartmentObjData"
                @handlerEditDepartmentDialog="handlerEditDepartmentDialog"
                @handlerEditDetermine="handlerEditDetermine"
                @handlerEditCloseDialog="handlerEditCloseDialog"
            >
            </editing-equipment>
        </dialog-wrapper>
        <!-- 部门权限 -->
        <xk-tree-shape
            v-if="department.dialogVisible"
            title="部门权限"
            :multiple="false"
            :checkAll="true"
            :show="department.dialogVisible"
            :list="selDepartmentTreeList"
            :selectObjData="selDepartmentObjData"
            @getNextLevelTreeList="getDepartmentNextLevelTreeList"
            @determine="departmentDetermine"
            @closeDialog="closeDepartmentDialog"
        >
        </xk-tree-shape>
        <!-- 弹窗内 -->
        <xk-tree-shape
            v-if="departmentPopUp.dialogVisible"
            title="部门权限"
            :multiple="true"
            :show="departmentPopUp.dialogVisible"
            :list="selPopUpDepartmentTreeList"
            :selectObjData="selPopUpDepartmentObjData"
            @getNextLevelTreeList="getPopUpDepartmentNextLevelTreeList"
            @determine="departmentPopUpDetermine"
            @closeDialog="closeDepartmentPopUpDialog"
        >
        </xk-tree-shape>
    </div>
</template>
<script>
import {
    // 业务组件
    DialogWrapper,
    Pagination,
} from "common-local";
import TableData from "@/components/scrollWrapper/Sub/TableData"
import {mapState} from "vuex";
import ExpandFilter from "./Sub/ExpandFilter";
import SetUpGroups from "./SSafeMonitorEquipmentList/SetUpGroups.vue";
import EditingEquipment from "./SSafeMonitorEquipmentList/EditingEquipment.vue";
import {VideoSurveillanceModel} from "@/models/VideoSurveillance.js";
import {CommonModel} from "@/models/Common.js";

import {removeTreeShapeDialog} from "@/libs/utils.js";
import onResize from "@/mixins/onResize";

export default {
    name: "SSafeMonitorEquipmentListScrollWrapper",
    mixins: [onResize],
    components: {
        ExpandFilter,
        TableData,
        Pagination,
        DialogWrapper,
        SetUpGroups,
        EditingEquipment,
    },
    data() {
        return {
            // 头部筛选
            formData: {
                data: [
                    {
                        type: "input",
                        value: "",
                        placeholder: "设备别名",
                        key: "deviceAlias",
                    },
                    {
                        type: "select",
                        value: "",
                        placeholder: "分组",
                        key: "groupCode",
                        list: [],
                    },
                    {
                        type: "cascader",
                        value: [],
                        placeholder: "所属班级",
                        key: "classId",
                        cascaderProps: {
                            multiple: false,
                            label: "name",
                            value: "id",
                            children: "children",
                        },
                        list: [],
                    },
                    {
                        type: "select",
                        value: "",
                        placeholder: "在线状态",
                        key: "status",
                        list: [
                            {
                                label: "在线",
                                value: "1",
                            },
                            {
                                label: "离线",
                                value: "0",
                            },
                        ],
                    },
                    {
                        type: "select",
                        value: "",
                        placeholder: "启用状态",
                        key: "openStatus",
                        list: [
                            {
                                label: "启用",
                                value: "1",
                            },
                            {
                                label: "未启用",
                                value: "0",
                            },
                        ],
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["videomonitoring:list"],
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                        auth: ["videomonitoring:list"],
                    },
                ],
                btnFormType: true,
            },
            departmentName: "", //部门权限
            loadingTable: false,
            table_config: {
                thead: [
                    {
                        label: "设备编号",
                        prop: "deviceId",
                        align: "center",
                    },
                    {
                        label: "序列号",
                        prop: "deviceSerial",
                        align: "center",
                        labelDescription: "设备序列号"
                    },
                    {
                        label: "设备别名",
                        prop: "deviceAlias",
                        align: "center",
                    },
                    {
                        label: "排序",
                        prop: "sort",
                        align: "center",
                        className: 'text-spacing-reduction'

                    },
                    {
                        label: "分组",
                        prop: "groupName",
                        align: "center",
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "所属班级",
                        prop: "className",
                        align: "center",
                    },
                    {
                        label: "部门权限",
                        prop: "limitsName",
                        align: "center",
                    },
                    {
                        label: "在线状态",
                        prop: "status",
                        type: "function",
                        align: "center",
                        callBack(row) {
                            return {
                                0: `<span style="color:#F26255;">离线</span>`,
                                1: `<span style="color:#14C79A;">在线</span>`,
                            }[row.status];
                        },
                    },
                    {
                        label: "启用状态",
                        prop: "openStatus",
                        type: "switch",
                        align: "center",
                    },
                    {
                        label: "创建时间",
                        prop: "createTime",
                        align: "center",
                        type: "function",
                        labelWidth: "160px",
                        callBack(row) {
                            if (row.createTime) {
                                return row.createTime.slice(0, 16)
                            }
                        }
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        className: 'text-spacing-reduction'
                    },
                ],
                check: false,
                height: "",
            },
            classList: [], // 班级
            table_data: [],
            total: 0,
            listQuery: {
                schoolId: "",
                deviceName: "",
                groupCode: "",
                classId: "",
                limits: {
                    id: "",
                    type: "",
                },
                status: "",
                openStatus: "",
                deviceAlias: "",
                pageNum: 1,
                pageRow: 20,
            },
            dialogObj: {
                title: "",
                dialogVisible: false,
                width: "800px",
                top: "5vh",
                val: "",
            },
            deviceList: [], // 设备列表
            grouping: [], // 设备分组
            dialogEditObj: {
                title: "编辑设备",
                dialogVisible: false,
                width: "640px",
                val: "",
            },
            // 部门权限-树形数据
            departmentTreeData: {
                schoolId: "",
                type: "teacher,organ",
                parentId: "",
                name: "",
                organType: "1,5",
            },
            department: {
                title: "部门权限",
                dialogVisible: false,
            },
            selDepartmentTreeList: [], // 树形数据
            selDepartmentObjData: [], // 已经选择的树形数据
            sizeChange: null,

            // 弹窗部门权限
            departmentPopUpTreeData: {
                schoolId: "",
                type: "teacher,organ",
                parentId: "",
                name: "",
                organType: "1,5",
            },
            departmentPopUp: {
                title: "部门权限",
                dialogVisible: false,
            },
            selPopUpDepartmentTreeList: [], // 树形数据
            selPopUpDepartmentObjData: [], // 已经选择的树形数据

            // 编辑
            editRuleForm: {
                id: "",
                name: "",
                groupCode: "",
                groupName: "",
                classId: "",
                limitsId: "",
                deviceAlias: "",
                sort: ""
            },
        };
    },
    created() {
        this.init();
    },
    activated() {
        this.$nextTick(() => {
            this.$refs.table.$refs.multipleTable.doLayout();
        });
        this.getNewGrouping();
    },
    mounted() {
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
            projectMark: (state) => state.projectMark,
        }),
    },
    methods: {
        init() {
            this.initAssignment();
            this.initPageData();
        },
        initAssignment() {
            this.listQuery.schoolId = this.schoolId;
            this.departmentTreeData.schoolId = this.schoolId;
            this.departmentPopUpTreeData.schoolId = this.schoolId;
        },
        async initPageData() {
            const videoSurveillanceModel = new VideoSurveillanceModel();

            await this.getList();
            await this.getNewGrouping();
            // 获取班级查询项
            videoSurveillanceModel
                .getClassList({schoolId: this.schoolId})
                .then((res) => {
                    if (res.data.code == "200") {
                        console.log("获取班级查询");
                        let data = res.data.data;
                        data = this.getNewChildren(data);

                        this.formData.data.forEach((item) => {
                            if ("classId" === item.key) {
                                item.list = data;
                                this.classList = data;

                                console.log(this.classList, "this.classList-");
                            }
                        });
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });

            await this.getNewDeviceList();
        },

        getNewGrouping() {
            // 获取查询项分组
            this._fet("/school/schoolDictValue/listByCondition", {
                dictKey: "vedioGroup",
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    let data = res.data.data;
                    data.forEach((item) => {
                        this.$set(item, "value", item.id);
                        this.$set(item, "label", item.name);
                    });
                    this.formData.data.forEach((item) => {
                        if ("groupCode" === item.key) {
                            item.list = data;

                            this.grouping = data;
                        }
                    });
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },

        async getNewDeviceList() {
            // 获取所有设备分组
            const videoSurveillanceModel = new VideoSurveillanceModel();
            await videoSurveillanceModel
                .getGroupList({schoolId: this.schoolId})
                .then((res) => {
                    if (res.data.code == "200") {
                        let resData = [];
                        let data = res.data.data;
                        let idx = 0;
                        let spKey = "未分组";
                        let spKeyList = [];

                        for (let key in data) {
                            let videoItem = {
                                id: idx,
                                name: key,
                                child: data[key],
                            };
                            idx++;
                            resData.push(videoItem);
                        }

                        resData.forEach((item) => {
                            this.$set(item, "checked", false);
                            this.$set(item, "isIndeterminate", false);
                            item.child.forEach((subItem) => {
                                this.$set(subItem, "checked", false);
                            });
                        });

                        let processingUngrouped = resData.sort((a, b) =>
                            a.name.localeCompare(b.name, "zh"),
                        );

                        processingUngrouped.forEach((item, index) => {
                            if (item.name == spKey) {
                                spKeyList = JSON.parse(JSON.stringify(item));
                                processingUngrouped.splice(index, 1);
                            }
                        });
                        this.deviceList = [spKeyList, ...processingUngrouped].filter((item) => {
                            return item.child ? item.child.length : 0;
                        });

                        console.log(this.deviceList, "分组列表-");
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },

        getNewChildren(data) {
            data.forEach((item) => {
                if (item.child.length > 0) {
                    this.$set(item, "children", item.child);
                    this.getNewChildren(item.child);
                }
            });

            return data;
        },
        sortList(key) {
            return function (item1, item2) {
                return item1[key] - item2[key];
            };
        },
        /**
         * @Description: 属性查询
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-16 17:06:23
         */
        async handlerDepartmentalCheck() {
            await this.getTreeData();
            this.department.dialogVisible = true;
        },
        /**
         * @Description: 获取树形数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-16 17:24:01
         */
        async getTreeData() {
            const commonModel = new CommonModel();
            await commonModel
                .getAddressBookList(this.departmentTreeData)
                .then((res) => {
                    if (res.data.code == "200") {
                        console.log(res.data.data, "tree树形数据");
                        this.selDepartmentTreeList = res.data.data;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        /**
         * @Description: 树形下一级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-16 17:23:15
         */
        async getDepartmentNextLevelTreeList(data) {
            const {id, name} = data;
            this.departmentTreeData.parentId = id || null;
            this.departmentTreeData.name = name;
            await this.getTreeData();
            this.$eventBroadcast("getNewTreeList");
        },

        clickBtn(info) {
            const {data, item} = info;
            const type = item.fn;
            console.log(data, "data");
            switch (type) {
                case "primary":
                    Object.keys(this.listQuery).forEach((key) => {
                        data.forEach((item) => {
                            if (item.key == key) {
                                if (key == "classId" && item.value.length > 0) {
                                    this.listQuery[key] = item.value[1];
                                } else {
                                    this.listQuery[key] = item.value.toString();
                                    // this.listQuery['classId'] = ''
                                }
                            }
                        });
                    });
                    this.listQuery.pageNum = 1;
                    console.log(this.listQuery, "this.listQuery");
                    this.getList();
                    break;
                case "reset":
                    this.formData.data.forEach((item) => {
                        item.value = "";
                    });
                    // 重置筛选项
                    this.listQuery.deviceName = "";
                    this.listQuery.groupCode = "";
                    this.listQuery.classId = "";
                    this.listQuery.limits = {
                        id: "",
                        type: "",
                    };
                    this.listQuery.status = "";
                    this.listQuery.openStatus = "";
                    this.listQuery.pageNum = 1;
                    this.listQuery.pageRow = 20;
                    // 重置树形结构
                    this.departmentTreeData.parentId = "";
                    this.departmentTreeData.name = "";
                    this.departmentName = "";
                    this.selDepartmentObjData = []; // 已经选择的树形数据
                    this.selDepartmentTreeList = [];

                    this.getList();
                    break;
                default:
                    break;
            }
        },
        /**
         * @Description: 筛选项
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-17 10:06:04
         * @param {*} data
         */
        changeSel(data) {
            console.log(data, "data--data");
            Object.keys(this.listQuery).forEach((key) => {
                if (key === data.key) {
                    this.listQuery[key] = data.value;
                }
            });
            console.log(this.listQuery, "this.listQuery");
            this.listQuery.pageNum = 1;
            this.getList();
        },
        /**
         * @Description: 年级查询
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-17 10:06:21
         */
        changeCascader(data) {
            let val = data.data.value[1];
            this.listQuery.classId = val;
            this.listQuery.pageNum = 1;
            this.getList();
        },
        switchHandler(row) {
            const videoSurveillanceModel = new VideoSurveillanceModel();
            videoSurveillanceModel
                .schoolVideoMonitoringEquipmentSave({
                    id: row.id,
                    openStatus: row.openStatus,
                })
                .then((res) => {
                    if (res.data.code == "200") {
                        this.getList();
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
            console.log(row, "row");
        },

        /**
         * @Description: 一键同步
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-16 15:26:25
         */
        handlerClickSync() {
            let _this = this;
            this.$confirm(
                "会自动同步监控系统的最新信息，设备增加、删除、修改信息后当前列表都会自动改变，是否确认同步？",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                },
            )
                .then(() => {
                    const videoSurveillanceModel = new VideoSurveillanceModel();
                    videoSurveillanceModel
                        .synchronizationDevice({
                            schoolId: _this.schoolId,
                            projectMark: _this.projectMark,
                        })
                        .then((res) => {
                            if (res.data.code == "200") {
                                _this.clickBtn({data: {}, item: {fn: "reset"}});
                                _this.$message.success("同步成功");
                            } else if (res.data && res.data.msg) {
                                _this.$message.error(res.data.msg);
                            }
                        });
                })
                .catch(() => {
                    //取消
                });
        },
        /**
         * @Description: 编辑
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-16 16:12:53
         */
        async edit(row) {
            await this.getNewGrouping();
            console.log(row, "row");
            const videoSurveillanceModel = new VideoSurveillanceModel();
            await videoSurveillanceModel
                .getEchoData({id: row.id})
                .then((res) => {
                    if (res.data.code == "200") {
                        let data = res.data.data;
                        this.editRuleForm.id = row.id;
                        this.editRuleForm.name = row.deviceName;
                        this.editRuleForm.groupCode = row.groupCode;
                        this.editRuleForm.groupName = row.groupName;
                        this.editRuleForm.sort = row.sort;
                        this.editRuleForm.deviceAlias = row.deviceAlias;
                        this.editRuleForm.groupName = row.groupName;
                        this.editRuleForm.classId = JSON.parse(data.classId);
                        this.editRuleForm.limitsId = "";
                        this.selPopUpDepartmentObjData = JSON.parse(
                            data.limitsId,
                        );
                        this.dialogEditObj.dialogVisible = true;
                        console.log(this.selPopUpDepartmentObjData, "---000");
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
            console.log(row, "row");
        },
        /**
         * @Description: 批量操作切换
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-15 08:46:25
         */
        async handlerBatchOperation(value) {
            await this.getNewDeviceList();
            switch (value) {
                case "1":
                    console.log("1");
                    this.dialogObj.title = "批量设置分组";
                    break;
                case "2":
                    console.log("2");
                    this.dialogObj.title = "批量设置部门权限";
                    break;
            }

            this.dialogObj.val = value;
            this.dialogObj.dialogVisible = true;
        },

        async getList() {
            this.loadingTable = true;
            const videoSurveillanceModel = new VideoSurveillanceModel();
            await videoSurveillanceModel
                .getEquipmentList(this.listQuery)
                .then((res) => {
                    if (res.data.code == "200") {
                        const data = res.data.data;
                        console.log(data, "data-list");
                        this.table_data = data.list;
                        this.total = data.total;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                    this.loadingTable = false;

                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.$refs.table.$refs.multipleTable.doLayout();
                        }, 200);
                    });
                });
        },
        /**
         * @Description: 展开弹窗树形结构
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-17 22:19:37
         */
        async handlerDepartmentalAuthorityDialog() {
            await this.getPopUpTreeData();
            this.departmentPopUp.dialogVisible = true;
        },
        async getPopUpTreeData() {
            const commonModel = new CommonModel();
            await commonModel
                .getAddressBookList(this.departmentPopUpTreeData)
                .then((res) => {
                    if (res.data.code == "200") {
                        console.log(res.data.data, "tree树形数据");
                        this.selPopUpDepartmentTreeList = res.data.data;
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        async getPopUpDepartmentNextLevelTreeList(data) {
            const {id, name} = data;
            this.departmentPopUpTreeData.parentId = id || null;
            this.departmentPopUpTreeData.name = name;
            await this.getPopUpTreeData();
            this.$eventBroadcast("getNewTreeList");
        },
        departmentPopUpDetermine(data) {
            this.selPopUpDepartmentObjData = data;
            removeTreeShapeDialog();

            if (this.dialogEditObj.dialogVisible) {
            } else {
            }
            this.departmentPopUpTreeData.parentId = "";
            this.departmentPopUpTreeData.name = "";
            this.departmentPopUp.dialogVisible = false;
        },
        closeDepartmentPopUpDialog() {
            this.departmentPopUp.dialogVisible = false;
            removeTreeShapeDialog();

            if (this.dialogEditObj.dialogVisible) {
            } else {
            }

            this.departmentPopUpTreeData.parentId = "";
            this.departmentPopUpTreeData.name = "";
        },
        handlerCloseDialog() {
            console.log(this.deviceList, "this.deviceList");

            this.deviceList.forEach((item) => {
                this.$set(item, "isIndeterminate", false);
                this.$set(item, "checked", false);

                if (item.child.length > 0) {
                    item.child.forEach((subItem) => {
                        this.$set(subItem, "checked", false);
                    });
                }
            });

            this.$eventBroadcast("initData");

            this.dialogObj.dialogVisible = false;
            this.selPopUpDepartmentObjData = [];
        },
        async handlerDetermine(ruleForm) {
            const videoSurveillanceModel = new VideoSurveillanceModel();
            let rule = ruleForm.ruleForm;
            let groupName = "";

            console.log("确定", rule, this.dialogObj.val);

            switch (this.dialogObj.val) {
                case "1":
                    if (!rule.equipment || rule.equipment.length == 0) {
                        return this.$message.error("请选择设备");
                    }

                    if (!rule.region) {
                        return this.$message.error("请选择分组");
                    }

                    this.grouping.forEach((item) => {
                        if (item.id == rule.region) {
                            groupName = item.name;
                        }
                    });

                    await videoSurveillanceModel
                        .setBatchGroup({
                            deviceIds: rule.equipment,
                            groupCode: rule.region,
                            groupName: groupName,
                        })
                        .then((res) => {
                            if (res.data.code == "200") {
                                this.$message.success("设置分组成功");
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                        });
                    break;
                case "2":
                    if (!rule.equipment || rule.equipment.length == 0) {
                        return this.$message.error("请选择设备");
                    }
                    if (!rule.departmentalAuthority) {
                        return this.$message.error("请选择部门权限");
                    }
                    await videoSurveillanceModel
                        .setBatchDebt({
                            deviceIds: rule.equipment,
                            limitsId: rule.limitsId,
                        })
                        .then((res) => {
                            if (res.data.code == "200") {
                                this.$message.success("设置部门权限成功");
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                        });
                    break;
            }

            this.handlerCloseDialog();
            this.clickBtn({data: {}, item: {fn: "reset"}});
        },

        // 树形选择
        departmentDetermine(data) {
            console.log(data, "结果");
            this.setDepartmentCreatedTreeData(data);
            removeTreeShapeDialog();
            this.departmentTreeData.parentId = "";
            this.departmentTreeData.name = "";
            this.department.dialogVisible = false;

            this.listQuery.pageNum = 1;
            this.getList();
        },
        // 树形关闭
        closeDepartmentDialog() {
            this.department.dialogVisible = false;
            removeTreeShapeDialog();
            this.departmentTreeData.parentId = "";
            this.departmentTreeData.name = "";
        },
        // 查询项
        setDepartmentCreatedTreeData(data) {
            if (data.length > 0) {
                this.selDepartmentObjData = data;
                this.listQuery.limits.type = data[0].type;
                this.listQuery.limits.id = data[0].id;

                this.departmentName = data[0].name;
            } else {
                this.selDepartmentObjData = [];
                this.listQuery.limits = {
                    id: "",
                    type: "",
                };
                this.departmentName = "";
            }
        },

        async handlerEditDepartmentDialog() {
            await this.getPopUpTreeData();
            this.departmentPopUp.dialogVisible = true;
            console.log(this.departmentPopUp.dialogVisible, "2");
        },
        /**
         * @Description: 编辑提交
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-19 09:16:26
         */
        handlerEditDetermine(ruleForm) {
            console.log(ruleForm, "ruleForm");
            let ruleFormData = JSON.parse(JSON.stringify(ruleForm));

            this.grouping.forEach((item) => {
                if (item.id == ruleFormData.groupCode) {
                    ruleFormData.groupName = item.name;
                }
            });
            ruleFormData.classId = JSON.stringify(ruleFormData.classId);

            delete ruleFormData.name;
            delete ruleFormData.limitsName;
            console.log(ruleFormData, "ruleFormData");

            const videoSurveillanceModel = new VideoSurveillanceModel();
            videoSurveillanceModel.editDevice(ruleFormData).then((res) => {
                if (res.data.code === "200") {
                    this.$message.success("保存成功");
                    this.handlerEditCloseDialog();
                    this.clickBtn({data: {}, item: {fn: "reset"}});
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description: 关闭编辑弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-06-19 09:16:47
         */
        handlerEditCloseDialog() {
            this.dialogEditObj.dialogVisible = false;
            this.departmentPopUpTreeData.parentId = "";
            this.departmentPopUpTreeData.name = "";
            this.selPopUpDepartmentObjData = [];
        },
    },
};
</script>
<style lang="scss" scoped>
.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    overflow: hidden;
    margin-bottom: 10px;
    border-radius: 4px;

    .expand-filter {
        padding: 0;
    }

    .options {
        white-space: nowrap;
        flex-shrink: 0;
    }
}
</style>
