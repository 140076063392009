<!-- 智慧安全/校园监控系统/视频监控 -->
<template>
    <div v-loading="loading" class="center-main">
        <iframe
            :src="url"
            :allowfullscreen="true"
            class="center-main__iframe"
        ></iframe>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "SSafeMonitorVideoSurveillanceScrollWrapper",
    data() {
        return {
            loading: true,
            url: '',
            firstTime: true,
        };
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
        }),
        iframeBaseUrl() {
            if (process.env.NODE_ENV === 'production') {
                return 'https://send.xinkaoyun.com:8022/dist/#/intelligentMonitoring?paramsData='
            } else {
                return 'http://124.70.25.71:8022/dist/#/intelligentMonitoring?paramsData='
            }
        }
    },
    mounted() {
        this.firstTime = false
        this.init()
    },
    activated() {
        // 在首次挂载、
        // 以及每次从缓存中被重新插入的时候调用
        if (this.firstTime) {
            this.init()
        }
    },
    deactivated() {
        this.url = ""
        this.firstTime = true;
    },
    methods: {
        init() {
            this._fet('/school/schoolVideoMonitoringEquipment/permissionList', {
                schoolId: this.schoolId
            }).then((res) => {
                this.url = this.iframeBaseUrl + JSON.stringify(res.data)
                console.log('[视频监控iframe链接]', this.url);
            }).finally(() => {
                this.loading = false
            })
        }
    },
};
</script>

<style scoped lang="scss">
.center-main {
    height: calc(100vh - 113px);
    padding: 10px 10px 10px 0;
    overflow: hidden;

    &__iframe {
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 4px;
    }
}
</style>
